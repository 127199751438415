
/*
 * Page | Quiz
 */

import { IRouteHandler } from "@sygnal/sse"; 


export class QuizPage implements IRouteHandler {

  constructor() {
  }

  setup() {
  }

  exec() {
  }

}
